<template>
	<div>
		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ArticleTopSection :page="store.vuepage.slotprops" backgroundclass="bglightgrey"></ArticleTopSection>

		<EventInfoSection :eventpage="store.vuepage.slotprops"></EventInfoSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content"
			:scriptinfo="store.vuepage.slotprops.article_content_script"
			:subheading="store.vuepage.slotprops.subheading"
			:extlink="getExtlink(0)"
		></ArticleSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content_sec"
			:scriptinfo="store.vuepage.slotprops.article_content_sec_script"
			:extlink="getExtlink(1)"
		></ArticleSection>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ArticleTileSection :tilenewsitems="store.vuepage.slotprops.eventstiles" addclasses="extrapadtop nopadbot"></ArticleTileSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bggrey"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
import ArticleTopSection from "@/components/ArticleTopSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import EventInfoSection from "@/components/EventInfoSection";
export default {
	name: "ArticleEvent",
	components: {
		EventInfoSection,
		ArticleTileSection,
		ArticleTopSection,
		ArticleSection,
		FooterSection,
		ShowTileSection,
		AdSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		},
		extlink() {
			if(this.store.vuepage.slotprops.event_link && this.store.vuepage.slotprops.event_link.length > 0) {
				return this.store.vuepage.slotprops.event_link
			}
			return ''
		},
		hasSecondaryContent() {
			if(this.store.vuepage.slotprops.article_content_sec && this.store.vuepage.slotprops.article_content_sec.length > 0) {
				return true
			}
			return false
		},
	},
	methods: {
		getExtlink(contentIndex) {
			if(contentIndex === 1 && this.hasSecondaryContent) {
				return this.extlink
			}
			else if(contentIndex === 0 && !this.hasSecondaryContent) {
				return this.extlink
			}
			return ''
		}
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.covid-note-section {
	width: 1000px;
	padding: 20px 30px;
	margin: 0 auto;
	color: var(--primarycolor);
	font-size: 12px;
	font-family: var(--primaryfont);
	font-weight: 600;
}
.external-link-section {
	margin: 20px auto;
	text-align: center;
}
@media screen and (max-width: 1000px) {
	.covid-note-section {
		width: calc(100% - 50px);
	}
}
@media screen and (max-width: 800px) {
	.covid-note-section {
		width: 90%;
	}
}
</style>