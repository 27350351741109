<template>
	<div class="shop-2sm-section" :class="bgclass">
		<a class="container" href="https://shop.2sm.com.au" target="_blank">
			<p class="partoftext">Visit The<br/>2SM Shop</p>
			<img :src="store.urlroot+'/public-static/2sm-tshirt.png?v=1'" alt="SRN Logo" />
		</a>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "Shop2SMBanner",
	components: {},
	props: {
		backgroundclass: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		domain() {
			return this.store.domain;
		},
		bgclass() {
			if(this.backgroundclass && this.backgroundclass.length > 0) return this.backgroundclass;
			return '';
		},
		vp() {
			return this.store.vuepage;
		},
	},
	methods: {

	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.shop-2sm-section {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: #DC2924;
}
.shop-2sm-section img {
	height: 160px;
}
.shop-2sm-section .container {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	gap: 20px;
}
.partoftext {
	padding: 20px 0;
	font-size: 30px;
	font-weight: bold;
	text-transform: uppercase;
	color: #FFF;
}

@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 599px) {
	.shop-2sm-section .container {
		flex-direction: column;
		gap: 10px;
	}
}

</style>