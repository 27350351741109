<template>
	<div>
		<HeroSectionTitleOnly :title="store.vuepage.slotprops.heading" :showlinkbutton="false"></HeroSectionTitleOnly>

		<AdSection v-if="vp.name !== 'advertising-enquiry'" adtype="header" backgroundclass="bglightgrey"></AdSection>

		<PartOfSRNSection v-if="vp.name !== 'advertising-enquiry'"></PartOfSRNSection>

		<ContactMap v-if="vp.name === 'contact-us'" :page="vp"></ContactMap>

		<ContactPageContent v-if="vp.name !== 'advertising-enquiry'" :page="vp"></ContactPageContent>

		<SubmitEventForm v-if="vp.name === 'submit-event'" :page="vp"></SubmitEventForm>

		<AdvertiseForm v-else-if="vp.name === 'advertising-enquiry'" :page="vp"></AdvertiseForm>

		<ContactForm v-else :page="vp"></ContactForm>

		<AdSection adtype="body" backgroundclass=""></AdSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bglightgrey"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import ContactPageContent from "@/components/ContactPageContent";
import ContactForm from "@/components/forms/ContactForm";
import ContactMap from "@/components/ContactMap";
import SubmitEventForm from "@/components/forms/SubmitEventForm";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly.vue";
import PartOfSRNSection from "@/components/PartOfSRNSection.vue";
import AdvertiseForm from "@/components/forms/AdvertiseForm.vue";
export default {
	name: "PublicForm",
	components: {
		PartOfSRNSection,
		HeroSectionTitleOnly,
		SubmitEventForm,
		ContactForm,
		ContactPageContent,
		FooterSection,
		ShowTileSection,
		AdSection,
		ContactMap,
		AdvertiseForm,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>