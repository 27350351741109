import { reactive } from 'vue'

let isDevServer = false;
if(window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
}

export const store = reactive({
	kalert: {
		shown: false,
		text: "",
		type: 'danger',
	},
	urlroot: window.urlroot,
	vuepage: window.vuepage,
	globals: window.vuepage.globals,
	pagetemplate: window.vuepage.pagetemplate,
	pagename: window.vuepage.name,
	pagetitle: window.vuepage.pageTitle,
	pagepath: '',
	loading: false,
	gtagua: window.gtagua,
	domain: window.domain,
	isDevServer: isDevServer,

	sideMenuIsOpen: false,
	fireplaypause: 0,
	adZones: [],
	adZoneContent: [],

	amDomains: ['2sm','2ad','2hd','2bs','2du','2bh','2el','2gf','2hc','2lf','2mg','2mo','2nz','2pk','2re','2tm',
		'2vm','4ak','4am','4gy','4kz','4wk','8ha','radio97','2lm','radio531'],
	fmDomains: ['brock','newfm','koolfm','fm929','fm1047','fm1003','gemfm','hillfm','maxfm','nowfm','realfm','roccyfm',
		'sunfm','zoofm','tripleg','zzzfm'],
	metroDomains: ['2sm'],
	searchVal: '',
})