<template>
	<div class="tile-article">
		<a v-if="store.loading === false" :href="newsitem.url" class="tile-linker" :data-targpagetpl="newsitem.pagetemplate">
			<div v-if="newsitem.hero_image && newsitem.hero_image.length > 0" class="image-parent" :style="bgimgoverride">
				<div class="image-container">
					<img class="hero-image" :src="newsitem.hero_image" :alt="newsitem.heading+' image'" />
				</div>
				<div v-if="tagText" class="tag" :class="tagClass">
					{{tagText}}
				</div>
			</div>
			<div class="news-card-bottom">
				<p class="tile-heading">{{newsitem.heading}}</p>
				<p class="tile-sub">{{newsitem.subheading}}</p>
			</div>
		</a>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TileArticle",
	components: {},
	props: {
		newsitem: Object
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		tagText() {
			if(this.newsitem.disable_tag === true) return null
			if(this.newsitem && this.newsitem.article_type) {
				if(this.newsitem.article_type === 'News') {
					return 'News'
				}
				else {
					return this.newsitem.article_type
				}
			}
			return null
		},
		tagClass() {
			if(this.tagText === 'Win') return 'bglight';
			else if(this.tagText === 'Shows') return 'bgblack';
			return '';
		},
		bgimgoverride() {
			if(this.newsitem.background_override_image && this.newsitem.background_override_image.length > 0) {
				return "background-image: url('"+this.newsitem.background_override_image+"') !important;";
			}
			return "";
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.tile-article {
	background: #FFFFFF;
	flex-basis: 23.5%;
	margin-left: 1%;
	margin-right: 1%;
	box-sizing: border-box;
	height: 500px;
	position: relative;
	margin-bottom: 40px;
}
.tile-article:nth-child(4n-3) { /* item 1, 5, 9 etc */
	margin-left: 0;
}
.tile-article:nth-child(4n) { /* item 4, 8, 12 etc */
	margin-right: 0;
}
.tile-linker {
	height: 100%;
	display: block;
	transition: all 0.3s ease-in-out;
	background-color: #FFF;
}
.image-parent {
	width: 100%;
	height: 70%;
	position: relative;
}
.image-container {
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	overflow: hidden;
}
.hero-image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: all 0.3s ease-in-out;
}
.tile-linker:hover .hero-image {
	transform: scale(1.05);
}
.news-card-bottom {
	padding: 20px 0;
	overflow: hidden;
	height: 30%;
}
.tile-heading {
	font-size: 20px;
	font-family: var(--primaryfont);
	font-weight: bold;
	margin-bottom: 10px;
}
.tile-sub {
	font-size: 16px;
	font-family: var(--primaryfont);
	line-height: 1.4;
}
.tag {
	position: absolute;
	background: #FFF;
	text-align: center;
	z-index: 1;
	font-family: var(--primaryfont);
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 600;
	bottom: 0;
	left: 0;
	padding: 5px 20px;
	letter-spacing: 0.03em;
}
.tag.bgblack {
	color: #FFF;
	background-color: #000;
}
.tag.bglight {
	background-color: var(--primarycolor);
	color: #FFF;
}

@media screen and (max-width: 1600px) {
	.tile-sub {
		font-size: 14px;
	}
}
@media screen and (max-width: 1050px) {
	.tile-article {
		width: 48%;
		flex-basis: 48%;
		height: 380px;
		margin-left: unset !important;
		margin-right: unset !important;
	}
}
@media screen and (max-width: 800px) {
	.tile-heading {
		font-size: 16px;
	}
	.tile-sub {
		font-size: 14px;
	}
}
@media screen and (max-width: 640px) {

}
@media screen and (max-width: 570px) {
	.tile-article {
		max-width: unset;
		width: 100%;
		flex-basis: 100%;
		height: 380px;
		margin-bottom: 20px;
	}
}
</style>