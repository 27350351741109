<template>
	<div>

		<HeroSectionTitleOnly :title="store.vuepage.slotprops.heading" :showlinkbutton="false"></HeroSectionTitleOnly>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<RepeaterContentSection :repeatercontent="repeaterContent"></RepeaterContentSection>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass=""></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import FooterSection from "@/components/FooterSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly.vue";
import ShowTileSection from "@/components/ShowTileSection.vue";
import RepeaterContentSection from "@/components/RepeaterContentSection.vue";
export default {
	name: "AboutSRN",
	components: {
		RepeaterContentSection,
		ShowTileSection,
		HeroSectionTitleOnly,
		FooterSection,
		AdSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		repeaterContent() {
			if(this.store.vuepage.slotprops.repeater && this.store.vuepage.slotprops.repeater.length > 0) {
				return this.store.vuepage.slotprops.repeater
			}
			return null
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>