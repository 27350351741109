<template>
	<div>

		<HeroSection :page="store.vuepage.slotprops.heropage" :showlinkbutton="true"></HeroSection>

		<PartOfSRNSection></PartOfSRNSection>

		<Shop2SMBanner v-if="store.domain === '2sm'"></Shop2SMBanner>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles"></ShowTileSection>

		<PodcastTileSection v-if="store.vuepage.slotprops.podcasttiles && store.vuepage.slotprops.podcasttiles.length > 0" :tileshows="store.vuepage.slotprops.podcasttiles"></PodcastTileSection>

		<AdSection adtype="body"></AdSection>

		<ArticleTileSection :tilenewsitems="store.vuepage.slotprops.newstiles"></ArticleTileSection>

		<FooterSection></FooterSection>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import HeroSection from "@/components/HeroSection";
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import PodcastTileSection from "@/components/PodcastTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import PartOfSRNSection from "@/components/PartOfSRNSection.vue";
import Shop2SMBanner from "@/components/Shop2SMBanner.vue";
export default {
	name: "HomePage",
	components: {
		Shop2SMBanner,
		PartOfSRNSection,
		FooterSection,
		ArticleTileSection,
		PodcastTileSection,
		ShowTileSection,
		AdSection,
		HeroSection
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>