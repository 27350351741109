<template>
	<div>
		<HeroSectionTitleOnly title="Local Events" :imgurl="eventSponsorHeader"></HeroSectionTitleOnly>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ArticleTileSection :tilenewsitems="events1" backgroundclass="nopadbot"></ArticleTileSection>

		<AdSection adtype="body" backgroundclass=""></AdSection>

		<ArticleTileSection :tilenewsitems="events2" backgroundclass=""></ArticleTileSection>

		<ArticleTileSection :tilenewsitems="events3" backgroundclass=""></ArticleTileSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass=""></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "AggregateEvents",
	components: {
		HeroSectionTitleOnly,
		FooterSection,
		ArticleTileSection,
		ShowTileSection,
		AdSection,
	},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		eventSponsorHeader() {
			if(this.store.globals.domainInfo) {
				return this.store.globals.domainInfo.events_header_sponsor_graphic
			}
			return ''
		},
		vp() {
			return this.store.vuepage
		},
		events1() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=0; i<4; i++) {
					if(this.vp.slotprops.eventstiles && this.vp.slotprops.eventstiles[i]) {
						ret.push(this.vp.slotprops.eventstiles[i]);
					}
				}
			}
			return ret;
		},
		events2() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=4; i<12; i++) {
					if(this.vp.slotprops.eventstiles && this.vp.slotprops.eventstiles[i]) {
						ret.push(this.vp.slotprops.eventstiles[i]);
					}
				}
			}
			return ret;
		},
		events3() {
			var ret = [];
			if(this.vp && this.store.loading === false) {
				for(var i=12; i<50; i++) {
					if(this.vp.slotprops.eventstiles && this.vp.slotprops.eventstiles[i]) {
						ret.push(this.vp.slotprops.eventstiles[i]);
					}
				}
			}
			return ret;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.d3mp .tile-holder {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-grow: 1;
	padding-left: 40px;
	padding-right: 40px;
}
@media screen and (min-width: 1700px) {
	.d3mp .tile-article {
		max-width: unset;
		width: 24% !important;
	}
}
@media screen and (max-width: 1700px) {
	.left-wing, .right-wing {
		display: none;
	}
	.d3mp .tile-holder {
		padding-left: 0;
		padding-right: 0;
	}
}
</style>