<template>
	<div>
		<HeroSection :page="store.vuepage.slotprops" :showlinkbutton="false"></HeroSection>

		<AdSection adtype="header" backgroundclass="bggrey"></AdSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content" :scriptinfo="store.vuepage.slotprops.article_content_script"></ArticleSection>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content_sec" :scriptinfo="store.vuepage.slotprops.article_content_sec_script"></ArticleSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bggrey"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import HeroSection from "@/components/HeroSection";
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
export default {
	name: "ArticlePodcasts",
	components: {
		ArticleSection,
		FooterSection,
		ShowTileSection,
		AdSection,
		HeroSection
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>