<template>
	<div class="hero-section" :style="bgimage" :class="{hasimage : imgurl.length > 0}">
		<div v-if="store.loading === false" class="container structure">
			<div v-if="bgimage.length === 0">
				<h1 class="text-white">{{title}}</h1>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "HeroSectionTitleOnly",
	components: {

	},
	props: {
		title: String,
		imgurl: {
			type: String,
			default: ''
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		bgimage() {
			if(this.imgurl && this.imgurl.length > 0) {
				return {
					backgroundImage: 'url(' + this.imgurl + ')'
				}
			}
			return '';
		}
	},
	methods: {},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.hero-section {
	height: 250px;
	max-height: 250px;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: var(--primarycolor);
	background-image: linear-gradient(to right, var(--primarydark), var(--primarycolor));
	position: relative;
	width: 100%;
	background-position: center;
}
.hero-section.hasimage {
	height: 500px;
	max-height: 500px;
}
.container {
	max-height: 100%;
}
h1 {
	font-family: var(--primaryfont);
	text-align: center;
	font-weight: 500;
}
.structure {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
}
.left-half {
	width: 49%;
}

@media screen and (max-width: 959px) {

}
@media screen and (max-width: 640px) {
	.hero-section {
		height: 200px;
		max-height: 200px;
		position: relative;
	}
	.left-half {
		width: 100%;
		text-align: center;
	}
}
</style>