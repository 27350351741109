<template>
	<map v-if="store.loading === false && latitude && longitude">
		<GoogleMap
			api-key="AIzaSyAXI9TurrUxFw0U9jwfoR49u9PwW9oRDE0"
			:style="'width: 100%; height: '+mapHeight"
			:center="center"
			:zoom="18"
		>
			<Marker :options="{ position: center }" />
		</GoogleMap>
	</map>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import { GoogleMap, Marker } from 'vue3-google-map'
export default {
	name: "ContactMap",
	components: {
		GoogleMap,
		Marker,
	},
	props: {
		page: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		mapHeight() {
			let screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			if(screenwidth < 768) return '300px'
			return '500px'
		},
		center() {
			return {
				lat: this.latitude,
				lng: this.longitude
			}
		},
		latitude() {
			let val = this.store.vuepage.slotprops.address_latitude
			if(val) return parseFloat(val)
			return null
		},
		longitude() {
			let val = this.store.vuepage.slotprops.address_longitude
			if (val) return parseFloat(val)
			return null
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>