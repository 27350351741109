<template>
	<div style="margin-top: 20px;">
		<select v-model="store.domain">
			<option v-for="d in domlist" :value="d" :key="d">{{d}}</option>
		</select>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DomSwitcher",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			//chosenDom: this.store.domain,
		}
	},
	computed: {
		domlist() {
			var d = [];
			for(let i=0; i<this.store.amDomains.length; i++) {
				d.push(this.store.amDomains[i]);
			}
			for(let i=0; i<this.store.fmDomains.length; i++) {
				d.push(this.store.fmDomains[i]);
			}
			return d;
		},
		ams() {
			return this.store.amDomains;
		}
	},
	methods: {},
	watch: {
		'store.domain': {
			handler() {
				this.$emit('close')
			}
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>