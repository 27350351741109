<template>
	<div class="hero-section" :class="backgroundclass">
		<div class="bottom-padder"></div>
		<div class="">
			<div class="container">
				<div class="heading-hold">
					<h1 v-if="store.loading === false && page" class="pageheading">{{page.heading}}</h1>
					<p v-if="page.nice_date && page.nice_date.length > 0" class="article-date">{{page.nice_date}}</p>
				</div>

				<div>
					<div class="image-container">
						<img v-if="store.loading === false && page && page.hero_image && page.hero_image.length > 0" class="hero-image" :src="page.hero_image" :alt="page.heading+' image'" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "ArticleTopSection",
	components: {},
	props: {
		page: Object,
		backgroundclass: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
h1.pageheading {
	line-height: 1.2;
	font-size: 40px;
	font-weight: 600;
	width: 90%;
	font-family: var(--primaryfont);
	text-align: left;
}
.article-date {
	text-align: left;
	margin: 15px 0 0;
	font-weight: bold;
}
.hero-section {
	background-color: #F5F5F5;
	position: relative;
}
.container {
	max-height: 100%;
	max-width: 960px;
	min-height: 400px;
	text-align: center;
	position: relative;
	padding-left: 0;
	padding-right: 0;
}
.wing-holder {
	max-width: 1770px;
	margin: 0 auto;
}
.left-wing, .right-wing {
	margin-top: -200px;
}
.heading-hold {
	padding-bottom: 40px;
	padding-top: 40px;
}
.image-container {
	width: 100%;
	height: 500px;
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.hero-image {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.bottom-padder {
	height: 150px;
	width: 100%;
	background-color: #FFF;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 0;
}
.wintag {
	text-transform: uppercase;
	font-family: var(--primaryfont);
	font-size: 14px;
	font-weight: 600;
	margin: 0 20px 20px;
	background-color: #FFDD00;
	padding: 8px 24px;
	border-radius: 15px;
	display: inline-block;
}
@media screen and (max-width: 1800px) {
	.wing-holder {
		max-width: calc(100% - 60px);
	}
}
@media screen and (max-width: 1380px) {
	.left-wing, .right-wing {
		display: none;
	}
}
@media screen and (max-width: 1050px) {
	.hero-section {
		padding-left: 50px;
		padding-right: 50px;
	}
}
@media screen and (max-width: 1000px) {
	h1.pageheading {
		font-size: 36px;
	}
	.heading-hold {
		padding-bottom: 30px;
		padding-top: 40px;
	}
}
@media screen and (max-width: 640px) {
	.image-container {
		height: 300px;
	}
}
@media screen and (max-width: 500px) {
	h1.pageheading {
		font-size: 28px;
	}
	.heading-hold {
		padding-top: 20px;
	}
}

</style>