<template>
	<div v-if="store.loading === false" class="article-section">
		<div class="container">
			<div v-if="page.name === 'contact-us'" class="article-inside">
				<p v-if="page.slotprops.subheading && page.slotprops.subheading.length > 0" class="article-subheading">{{page.slotprops.subheading}}</p>
				<div v-html="page.slotprops.article_content"></div>
				<div class="column-holder">
					<div class="colwc">
						<div class="cont-det-section" v-if="hasCallUs">
							<p class="article-subheading">Call Us</p>
							<div class="contdet-hold" v-if="page.slotprops.general_phone && page.slotprops.general_phone.length > 0">
								<p class="cont-heading">General Phone</p>
								<p class="contdet">{{page.slotprops.general_phone}}</p>
							</div>
							<div class="contdet-hold" v-if="page.slotprops.sales_phone && page.slotprops.sales_phone.length > 0">
								<p class="cont-heading">Sales Phone</p>
								<p class="contdet">{{page.slotprops.sales_phone}}</p>
							</div>
							<div class="contdet-hold" v-if="page.slotprops.news_phone && page.slotprops.news_phone.length > 0">
								<p class="cont-heading">News Phone</p>
								<p class="contdet">{{page.slotprops.news_phone}}</p>
							</div>
							<div class="contdet-hold" v-if="page.slotprops.studio_phone && page.slotprops.studio_phone.length > 0">
								<p class="cont-heading">Studio Phone</p>
								<p class="contdet">{{page.slotprops.studio_phone}}</p>
							</div>
						</div>

						<div class="cont-det-section" v-if="hasLocation">
							<p class="article-subheading">Our Location</p>
							<div class="contdet-hold" v-if="page.slotprops.location_studio_address && page.slotprops.location_studio_address.length > 0">
								<p class="cont-heading">Studio Address</p>
								<p class="contdet" v-html="ntobr(page.slotprops.location_studio_address)"></p>
							</div>
							<div class="contdet-hold" v-if="page.slotprops.reception_hours && page.slotprops.reception_hours.length > 0">
								<p class="cont-heading">Reception Open</p>
								<p class="contdet" v-html="ntobr(page.slotprops.reception_hours)"></p>
							</div>
						</div>

					</div>
					<div class="colwc">
						<div class="cont-det-section" v-if="hasEmailUs">
							<p class="article-subheading">Send Message</p>
							<div class="contdet-hold" v-if="page.slotprops.general_email && page.slotprops.general_email.length > 0">
								<p class="cont-heading">General</p>
								<p class="contdet">{{page.slotprops.general_email}}</p>
							</div>
							<div class="contdet-hold" v-if="page.slotprops.advertising_email && page.slotprops.advertising_email.length > 0">
								<p class="cont-heading">Advertising</p>
								<p class="contdet">{{page.slotprops.advertising_email}}</p>
							</div>
							<div class="contdet-hold" v-if="page.slotprops.news_email && page.slotprops.news_email.length > 0">
								<p class="cont-heading">Newsroom</p>
								<p class="contdet">{{page.slotprops.news_email}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="article-inside">
				<div v-html="page.slotprops.article_content"></div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ContactPageContent",
	components: {},
	props: {
		page: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		hasCallUs() {
			let sp = this.page.slotprops
			if(sp.general_phone && sp.general_phone.length > 0) {
				return true
			}
			else if(sp.sales_phone && sp.sales_phone.length > 0) {
				return true
			}
			else if(sp.news_phone && sp.news_phone.length > 0) {
				return true
			}
			else if(sp.studio_phone && sp.studio_phone.length > 0) {
				return true
			}
			return false
		},
		hasLocation() {
			let sp = this.page.slotprops
			if(sp.location_studio_address && sp.location_studio_address.length > 0) {
				return true
			}
			else if(sp.reception_hours && sp.reception_hours.length > 0) {
				return true
			}
			return false
		},
		hasEmailUs() {
			let sp = this.page.slotprops
			if(sp.general_email && sp.general_email.length > 0) {
				return true
			}
			else if(sp.advertising_email && sp.advertising_email.length > 0) {
				return true
			}
			else if(sp.news_email && sp.news_email.length > 0) {
				return true
			}
			return false
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.article-inside {
	width: 1000px;
	padding: 80px 50px 80px;
	margin: 0 auto;
}
.article-subheading {
	font-size: 30px;
}
.column-holder {
	display: flex;
	justify-content: space-between;
}
.colwc {
	width: 48%;
}
.link-text-in-page {
	display: block;
	text-transform: uppercase;
	font-size: 13px;
	margin-bottom: 20px;
	transition: all 0.2s ease-in-out;
}
.link-text-in-page {
	color: var(--primarycolor);
}
.cont-det-section {
	margin-bottom: 30px;
}
.cont-det-section .article-subheading {
	margin-bottom: 20px;
}
.contdet-hold {
	margin-bottom: 20px;
}
.cont-heading {
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 0;
	letter-spacing: 0.05em;
}
.contdet {
	font-size: 14px;
	margin-bottom: 8px;
}
/*.link-text-in-page:hover {
	color: #FFF;
}*/
@media screen and (max-width: 1100px) {
	.article-inside {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}
@media screen and (max-width: 840px) {
	.column-holder {
		flex-wrap: wrap;
	}
	.colwc {
		width: 100%;
	}
}
@media screen and (max-width: 640px) {
	.article-inside {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

</style>