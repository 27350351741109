<template>
	<div class="hero-section" :style="bgimgoverride">
		<div class="hero-gradient-hold"></div>
		<div v-if="page" class="container structure">
			<div v-if="store.loading === false && page.hero_image && page.hero_image.length > 0" class="left-half">
				<div class="image-container">
					<img class="hero-image" :class="{nonEtched : page.heroImageIsEtched === false}" :src="page.hero_image" :alt="page.heading+' image'" />
				</div>
			</div>
			<div class="right-half">
				<div v-if="store.loading === false && showlinkbutton === false" class="content-hold">
					<p class="hero-title text-white">{{page.heading}}</p>
					<p class="hero-subheading text-white" v-html="page.subheading"></p>
				</div>
				<a v-if="store.loading === false && showlinkbutton === true" class="content-hold" :href="page.url" :data-targpagetpl="page.pagetemplate">
					<p class="hero-title text-white">{{page.heading}}</p>
					<p class="hero-subheading text-white" v-html="page.subheading"></p>
				</a>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "HeroSection",
	components: {

	},
	mixins: [globalMixin],
	props: {
		page: Object,
		showlinkbutton: Boolean,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		bgimgoverride() {
			if(this.page && this.page.background_override_image && this.page.background_override_image.length > 0) {
				return "background-image: url('"+this.page.background_override_image+"') !important;";
			}
			return "";
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.hero-section {
	position: relative;
	height: 500px;
	max-height: 500px;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: var(--primarycolor);
	background: linear-gradient(to right, var(--primarydark), var(--primarycolor));
}
.hero-gradient-hold {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 300px;
	background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
}
.hero-title {
	font-size: 65px;
	font-family: var(--primaryfont);
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 20px;
}
.hero-subheading {
	font-size: 22px;
	font-family: var(--primaryfont);
	margin-bottom: 25px;
	font-weight: 500;
}
.container {
	max-height: 100%;
}
.structure {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 100%;
}
.right-half {
	width: 49%;
	align-self: flex-end;
	margin-bottom: 80px;
}
.left-half {
	max-height: 100%;
	height: 100%;
	position: relative;
	width: 49%;
	overflow: hidden;
}
.content-hold {
	display: block;
}
.image-container {
	max-height: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.hero-image {
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.hero-image.nonEtched {
	mask: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 7%, rgba(0,0,0,0.5) 10%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.3) 93%, rgba(0,0,0,0) 100%);
	height: 100%;
	width: 100%;
	object-fit: cover;
}

@media screen and (max-width: 1630px) {

}
@media screen and (max-width: 1220px) {

}
@media screen and (max-width: 959px) {
	.container {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
	.hero-gradient-hold {
		display: none;
	}
	.structure {
		display: block;
		position: relative;
	}
	.hero-section {
		height: 500px;
		max-height: 500px;
	}
	.hero-image.nonEtched {
		mask: unset;
	}
	.right-half {
		width: 100%;
		position: absolute;
		top: 0;
		z-index: 1;
		text-align: center;
		display: flex;
		height: 100%;
		align-items: flex-end;
		padding-bottom: 30px;
		background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0.9) 100%);
	}
	.right-half .content-hold {
		width: 90%;
		margin: 0 auto;
	}
	.skeleton-hold {
		text-align: center;
	}
	.skeleton-hold > div {
		margin-left: auto;
		margin-right: auto;
	}
	.left-half {
		width: 100%;
		height: 100%;
	}
	.hero-title {
		font-size: 40px;
	}
	.hero-subheading {
		font-size: 18px;
	}
}
@media screen and (max-width: 900px) {
	.hero-section {
		position: relative;
	}
}
@media screen and (max-width: 600px) {
	.hero-section {
		height: calc(100vh - 90px);
		max-height: 100vh;
	}
}
@media screen and (max-width: 500px) {
	.right-half .content-hold {
		width: 80%;
	}
}
</style>