<template>
	<div v-if="store.loading === false && repeatercontent && repeatercontent.length > 0" class="repeater-section" :class="addclassStr">
		<div v-for="(rowitem, index) in repeatercontent" :key="index" class="repeater-item" :class="{evenrow : index % 2}">
			<div class="repeater-image" :style="getBackgroundImage(rowitem)">
			</div>
			<div class="repeater-content">
				<div class="repeater-content-inside">
					<div class="repeater-title">
						{{rowitem.heading}}
					</div>
					<div>
						<div class="article-inside" v-html="rowitem.content"></div>
						<div v-if="rowitem.btn_url && rowitem.btn_url.length > 0 && rowitem.btn_text && rowitem.btn_text.length > 0" class="repeater-button">
							<a :href="rowitem.btn_url" class="primary-button bgdark textwhite" target="_blank">
								<span>{{rowitem.btn_text}}</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "RepeaterContentSection",
	components: {},
	props: {
		addclasses: String,
		repeatercontent: {
			type: Array,
			default: () => []
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		addclassStr() {
			let str = "";
			if(this.addclasses && this.addclasses.length > 0) str += this.addclasses;
			return str;
		},
	},
	methods: {
		getBackgroundImage(rowitem) {
			if(rowitem.hero_image && rowitem.hero_image.length > 0) {
				return {
					backgroundImage: 'url(' + rowitem.hero_image + ')'
				}
			}
			return '';
		}
	},
	watch: {

	},
	mounted() {

	}
}
</script>
<style scoped>
.repeater-item {
	display: flex;
	position: relative;
	background-color: #F6F6F6;
}
.repeater-image {
	width: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.repeater-content {
	width: 50%;
}
.repeater-item.evenrow {
	flex-direction: row-reverse;
}
.repeater-content-inside {
	max-width: 885px;
	padding: 100px;
}
.evenrow .repeater-content-inside {
	float: right;
}
.repeater-title {
	font-size: 30px;
	font-family: var(--primaryfont);
	font-weight: 500;
	margin-bottom: 30px;
}

@media screen and (max-width: 1150px) {
	.repeater-content-inside {
		padding: 50px;
	}
}
@media screen and (max-width: 950px) {
	.repeater-item {
		flex-wrap: wrap;
	}
	.repeater-image {
		width: 100%;
		height: 400px;
	}
	.repeater-content {
		width: 100%;
	}
	.repeater-content-inside {
		padding: 70px;
	}
}
@media screen and (max-width: 640px) {
	.repeater-content-inside {
		padding: 50px 30px;
	}
}
</style>