<template>
	<div>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ArticleTopSection :page="store.vuepage.slotprops"></ArticleTopSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content" :scriptinfo="store.vuepage.slotprops.article_content_script"></ArticleSection>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ArticleSection :content="store.vuepage.slotprops.article_content_sec" :scriptinfo="store.vuepage.slotprops.article_content_sec_script" addclasses="extrapadbot"></ArticleSection>

		<ArticleTileSection :tilenewsitems="store.vuepage.slotprops.newstiles" addclasses="extrapadtop"></ArticleTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
import ArticleTopSection from "@/components/ArticleTopSection";
import ArticleTileSection from "@/components/ArticleTileSection";
export default {
	name: "ArticleNews",
	components: {
		ArticleTileSection,
		ArticleTopSection,
		ArticleSection,
		FooterSection,
		AdSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>