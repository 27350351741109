<template>
	<div class="prog-guide-section bglightgrey">
		<div class="container">
			<p class="prog-heading">Program Guide</p>
			<div class="day-choose">
				<div class="day-tab" v-for="day in guide" :key="day.dayname" :class="{activeday : day.dayname === activeDay}" @click="activeDay = day.dayname">
					<div class="dayselect">{{day.dayname}}</div>
				</div>
			</div>
			<div class="day-content">
				<div class="day-item" v-for="day in guide" :key="day.dayname" :class="{activeday : day.dayname === activeDay}">
					<div v-for="(item, index) in day.items" :key="index" class="day-list-item">
						<div class="times">{{item.times}}</div>
						<div>{{item.showname}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ProgramGuide",
	components: {},
	props: {
		guide: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			activeDay: null,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {
		this.activeDay = this.guide[0].dayname;
	}
}
</script>

<style scoped>
.prog-guide-section {
	padding-top: 60px;
	padding-bottom: 60px;
	display: flex;
}
.prog-heading {
	font-size: 20px;
	font-family: var(--primaryfont);
	font-weight: bold;
	margin-bottom: 10px;
}
.day-choose {
	width: 100%;
	background-color: #222;
	color: #FFF;
	margin-top: 20px;
	display: flex;
}
.day-tab {
	padding: 10px 20px 6px;
	cursor: pointer;
}
.day-tab.activeday {
	border-bottom: 4px solid #FFF;
}
.day-content {
	padding: 20px;
	position: relative;
}
.day-item {
	display: none;
	min-height: 200px;
}
.day-item.activeday {
	display: block;
}
.day-list-item {
	display: flex;
}
.times {
	min-width: 120px;
	font-weight: bold;
}

@media screen and (max-width: 640px) {
	.day-tab {
		padding: 10px 10px 6px;
		font-size: 14px;
	}
}
@media screen and (max-width: 450px) {
	.day-tab {
		padding: 10px 6px 6px;
		font-size: 12px;
	}
}
</style>