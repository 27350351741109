export const globalMixin = {
	methods: {
		numdisplay: function (num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		ntobr(str) {
			return str.replaceAll("\n", "<br/>");
		},
		areArraysIdentical(arrA, arrB) {
			if (arrA === arrB) return true;
			if (arrA == null || arrB == null) return false;
			if (arrA.length !== arrB.length) return false;
			arrA.sort();
			arrB.sort();
			// If you don't care about the order of the elements inside the array, you should sort both arrays here.
			// Please note that calling sort on an array will modify that array. You might want to clone your array first.
			for (var i = 0; i < arrA.length; ++i) {
				if (arrA[i] !== arrB[i]) return false;
			}
			return true;
		},
		getSkin() {
			if(this.store.amDomains.includes(this.store.domain)) return 'am';
			else return 'fm';
		},
		registerAdZone(zoneId) {
			let adZoneIndex = this.store.adZones.length; //index of the item we're about to add
			this.store.adZones.push(zoneId);
			return adZoneIndex;
		},
		setAdContent(arr) {
			let newarr = [];
			for(let myitemkey in arr) {
				newarr.push(arr[myitemkey].html);
			}
			this.store.adZoneContent = newarr;
		},
		pagepathmutation(routepath) {
			let adjusted = routepath.replace(this.store.urlroot, '');
			if (adjusted.slice(adjusted.length - 1) === "/") {
				adjusted = adjusted.slice(0, -1);
			}
			this.store.pagepath = adjusted;
		},
		setVuepage(vp) {
			this.store.vuepage = vp;
			if (vp.globals) {
				this.store.globals = vp.globals;
			}
		},
		showKalert(responseObject) {
			this.store.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) this.store.kalert.text = responseObject.text
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger'
			if (responseObject.success === 1) responseObject.alerttype = 'success'
			this.store.kalert.type = responseObject.alerttype
			if (responseObject.type !== "" && responseObject.type !== undefined) this.store.kalert.type = responseObject.type
			this.store.kalert.shown = true
		},
		closeKalert() {
			this.store.kalert.shown = false;
		},
	},
}